// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-about-js": () => import("./../../../src/pages/about/about.js" /* webpackChunkName: "component---src-pages-about-about-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blogs-blogs-js": () => import("./../../../src/pages/blogs/Blogs.js" /* webpackChunkName: "component---src-pages-blogs-blogs-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/Posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

